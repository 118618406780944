<template>
    <b-row>
        <b-card-group deck>
            <b-card title="Access Authentication">
                <h4>How To Use?</h4>
                <b-card-text>
                    Secure your information with increased security measures. Authenticate the devices to ensure that
                    your information is being accessed from a trusted device. By enabling this feature, Apollo Dry
                    Cleaning Software will only be accessed through devices which are permitted by the administrator .
                    It is fast easy and reliable. Follow the below mentioned steps to enable this feature for your
                    software.
                </b-card-text>
            </b-card>
            <b-card>
                <h4>Authenticated Access Control</h4>
                <b-form-checkbox checked="false" name="check-button" switch inline>
                    Do you want to enable Authenticated Access Control
                </b-form-checkbox>
            </b-card>
        </b-card-group>
    </b-row>

</template>
  
<script>
import {
    BCard, BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BCardText, BCardGroup, BFormCheckbox
} from 'bootstrap-vue'

export default {
    components: {
        BCardText,
        BFormInput,
        BFormGroup,
        BForm,
        BRow,
        BCol,
        BButton,
        BCard,
        BCardGroup,
        BFormCheckbox
    },
    data() {
        return {

        }
    },

}
</script>
   